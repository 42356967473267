import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Image from '~/components/atoms/Image/Image';
import Menu from '~/components/atoms/Menu/Menu';

type clickHandle = {
  (): void;
};

type Props = {
  menu: boolean;
  src: string;
  alt: string;
  srcmobile: string;
  clickHandle: clickHandle;
};

const HeroImage: React.FC<Props> = ({
  menu,
  src,
  alt,
  srcmobile,
  clickHandle,
}) => {
  const handleOutsideClick = () => {
    clickHandle();
  };

  const handleMenuClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <>
      <Container>
        <Image src={src} alt={alt} width={1200} srcmobile={srcmobile} />
        {menu ? (
          <MenuContainer onClick={handleMenuClick}>
            <Menu white={false} />
          </MenuContainer>
        ) : null}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  height: auto;
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 32px;
  left: 8rem;
`;

export default HeroImage;
