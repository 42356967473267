import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useMobile } from '~/utils/hook-functions';

const LanguageSelector: React.FC = ({ enLink, deLink }) => {
  const mobile = useMobile();

  return (
    <>
      <Container>
        {mobile ? (
          <span>
            <Link to={deLink || '/'} hrefLang="de">
              DE
            </Link>{' '}
            |{' '}
            <Link to={enLink || '/en/'} hrefLang="en">
              EN
            </Link>
          </span>
        ) : (
          <span>
            <Link to={deLink || '/'} hrefLang="de">
              DEUTSCH
            </Link>{' '}
            |{' '}
            <Link to={enLink || '/en/'} id="lang-en" hrefLang="en">
              ENGLISH
            </Link>
          </span>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  @media (min-width: 800px) {
    margin-left: auto;
    align-self: center;
  }

  @media (max-width: 800px) {
    align-self: center;
    margin-left: 3rem;
  }

  a {
    font-family: var(--primary-font-family);
    font-weight: 700;
    font-size: var(--primary-font-size);
    color: var(--primary-font-color);
  }
`;

export default LanguageSelector;
