import React from 'react';
import { Link } from 'gatsby';
import NeuroLogo from '@assets/svgs/logo.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Logo: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <Link to={i18n.language === 'de' ? '/' : '/en/'}>
        <NeuroLogo width={300} />
      </Link>
    </>
  );
};

export default Logo;
