import React from 'react';
import styled from '@emotion/styled';
import BurgerMenu from '@assets/svgs/burger-menu.svg';
import Logo from '~/components/atoms/Logo/Logo';
import LanguageSelector from '~/components/atoms/LanguageSelector/LanguageSelector';

declare function clickHandle(): void;

/* eslint-disable */
const Header = ({ clickHandle, enLink, deLink }) => {
  const clickHandler = () => {
    clickHandle();
  };

  return (
    <>
      <Container>
        <FlexContainer>
          <BurgerItem onClick={clickHandler} id="burger-menu">
            <BurgerMenu width={30} />
          </BurgerItem>
          <LanguageSelector enLink={enLink} deLink={deLink}/>
          <FloatLogo>
            <Logo />
          </FloatLogo>
        </FlexContainer>
      </Container>
    </>
  );
};
/* eslint-enable */

const Container = styled.header`
  @media (min-width: 1200px) {
    width: 1200px;
  }

  margin-top: 3rem;
  background-color: white;
  margin-bottom: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 800px) {
    justify-content: unset;
  }

  justify-content: center;
`;

const BurgerItem = styled.div`
  @media (min-width: 800px) {
    margin-left: 8rem;
    width: 30px;
    margin-bottom: 1rem;
  }

  @media (max-width: 800px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  align-self: center;
  cursor: pointer;
  padding: 10px;
`;

const FloatLogo = styled.div`
  @media (min-width: 800px) {
    margin-left: 3.5rem;
  }
`;

export default Header;
