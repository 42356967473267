/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import useScreenSize, { BreakPoint } from 'use-screen-size';

export const useMobile = () => {
  const size = useScreenSize();
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(true);
    if (
      size.screen === BreakPoint.m ||
      size.screen === BreakPoint.xl ||
      size.screen === BreakPoint.l
    ) {
      setMobile(false);
    }
  }, [size]);

  return mobile;
};
