import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ImportantServices: React.FC = () => {
  const { i18n } = useTranslation();

  const useGermanServices = () => {
    return (
      <>
        Unsere Leistungen: <Link to="/neurologie/">Neurologie</Link> |{' '}
        <Link to="/neuropsychologie/">Neuropsychologie</Link> |{' '}
        <Link to="/psychiatrie/">Psychiatrie</Link> |{' '}
        <Link to="/schlafmedizin-muenchen/">Schlafmedizin</Link> |{' '}
        <Link to="/sportmedizin-laktattest/">Sportmedizinischer Check-up</Link>{' '}
        | <Link to="/sportmedizin-laktattest/">Laktattest</Link>
        <br />
        <Link to="/">Injektion von Botulinumtoxin</Link> |{' '}
        <Link to="/">ADHS Testung- und Behandlung</Link>
        <p>
          Krankheitsbilder:{' '}
          <Link to="/multiple-sklerose-therapie-muenchen/">
            Multiple Sklerose
          </Link>{' '}
          | <Link to="/demenz-alzheimer-muenchen/">Demenz</Link> |{' '}
          <Link to="/schlaganfall-therapie-muenchen/">Schlaganfall</Link> |{' '}
          <Link to="/parkinson-therapie/">Parkinson</Link> |{' '}
          <Link to="/angststoerungen/">Angststörungen</Link> |{' '}
          <Link to="/depression/">Depression</Link> |{' '}
          <Link to="/gehirnerschuetterung-schaedel-hirn-trauma/">
            Schädel-Hirn-Trauma
          </Link>{' '}
        </p>
        <p>
          <Link to="/karpaltunnelsyndrom/">Karpaltunnelsyndrom</Link> |{' '}
          <Link to="/migraene-kopfschmerzen/">Migräne</Link> |{' '}
          <Link to="/polyneuropathie/">Polyneuropathie</Link> |{' '}
          <Link to="/blog/posts/Aufmerksamkeits-Defizit-Hyperaktivitäts-Störung">
            ADHS
          </Link>
        </p>
      </>
    );
  };

  const useEnglishServices = () => {
    return (
      <>
        Our services: <Link to="/en/neurology/">Neurology</Link> |{' '}
        <Link to="/en/neuropsychology/">Neuropsychology</Link> |{' '}
        <Link to="/en/psychiatry/">Psychiatry</Link> |{' '}
        <Link to="/en/sleep-medicine-munich/">Sleep Medicine</Link> |{' '}
        <Link to="/en/sports-medicine-lactatetest/">
          Sports Medicine Check-up
        </Link>{' '}
        | <Link to="/en/sports-medicine-lactatetest/">Lactate Test</Link>
        <p>
          Disease patterns:{' '}
          <Link to="/en/multiple-sclerosis-therapy-munich/">
            Multiple Sclerosis
          </Link>{' '}
          | <Link to="/en/dementia-alzheimer-munich/">Dementia</Link> |{' '}
          <Link to="/en/stroke-therapy-munich/">Stroke</Link> |{' '}
          <Link to="/en/parkinson-therapy/">Parkinson</Link> |{' '}
          <Link to="/en/anxiety-disorders/">Anxiety Disorders</Link> |{' '}
          <Link to="/en/depression/">Depression</Link> |{' '}
          <Link to="/en/traumatic-brain-injury/">Traumatic Brain Injury</Link>{' '}
        </p>
        <p>
          <Link to="/en/carpal-tunnel-syndrome/">Carpal Tunnel Syndrome</Link> |{' '}
          <Link to="/en/migraine-headache/">Migraine</Link> |{' '}
          <Link to="/en/polyneuropathy/">Polyneuropathy</Link>
        </p>
      </>
    );
  };

  return (
    <>
      <Container>
        {i18n.language === 'de' ? useGermanServices() : useEnglishServices()}
      </Container>
    </>
  );
};

const Container = styled.div`
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: var(--primary-font-size);
  line-height: 1.5;
  flex-basis: 100%;
  text-align: center;
  margin-bottom: 0;
  margin-bottom: 1rem;
`;

export default ImportantServices;
