/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from '@emotion/styled';
import { useMobile } from '~/utils/hook-functions';

type Props = {
  src: string;
  alt: string;
  width?: number;
  override?: boolean;
  srcmobile?: string;
};

const Image: React.FC<Props> = ({ src, alt, width, override, srcmobile }) => {
  const mobile = useMobile();

  return (
    <>
      <picture>
        {srcmobile ? (
          <source media="(max-width: 650px)" srcSet={srcmobile} />
        ) : null}
        <ImageCSS
          src={src}
          alt={alt}
          css={{ maxWidth: mobile ? (override ? width : '') : width }}
          loading="lazy"
        />
      </picture>
    </>
  );
};

const ImageCSS = styled.img`
  width: 100%;
  height: auto;
`;

export default Image;
