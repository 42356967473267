import styled from '@emotion/styled';
import React from 'react';

export const SmallCVContainer = styled.div`
  color: black;
  font-size: var(--primary-font-size);
  line-height: 1.5;

  @media (min-width: 1200px) {
    margin-left: 150px;
  }

  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;

  h2 {
    font-size: var(--primary-font-size);
    font-weight: bold;
  }

  h1,
  h2 {
    margin: 0;
  }

  li > p {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
`;

export const ImageContainer = styled.div`
  @media (min-width: 800px) {
    margin-left: 2rem;
  }

  margin: auto;
`;

export const ImageContainerHome = styled.div`
  @media (max-width: 1200px) {
    margin: auto;
  }
`;

export const Container = styled.article`
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 1200px) {
    width: 1250px;
  }
`;

export const ContainerSmall = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1200px) {
    width: 1000px;
  }

  li {
    margin-right: 2rem;
    list-style-position: outside;
  }

  ul,
  h3 {
    font-size: var(--primary-font-size);
  }

  h1,
  h2,
  h3,
  p {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  p {
    font-size: var(--primary-font-size);
    line-height: 1.5;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: var(--primary-font-size);
  line-height: 1.5;
  margin-top: 2rem;

  img {
    align-self: center;
  }
`;

export const FlexContainerNull = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: var(--primary-font-size);
  line-height: 1.5;
  margin-top: 0;
`;

export const List = styled.div`
  @media (min-width: 1200px) {
    width: 500px;
  }

  ul {
    list-style: none;
    text-indent: -0.8rem;
    padding-left: 3rem;
  }

  li::before {
    content: '- ';
    padding-right: 0.3rem;
  }
`;

type ContextFunction = (str: string) => string;
const cf: ContextFunction = s => s;

export const TranslationContext = React.createContext(cf);
