import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MenuItems = [
  { label: 'Home', labelEn: 'Home', route: '/', routeEn: '/en/', id: 0 },
  {
    label: 'Unsere Praxis',
    labelEn: 'Our Practice',
    route: '/praxis/',
    routeEn: '/en/practice/',
    id: 1,
  },
  {
    label: 'Neurologie',
    labelEn: 'Neurology',
    route: '/neurologie/',
    routeEn: '/en/neurology/',
    id: 2,
  },
  {
    label: 'Neuropsychologie',
    labelEn: 'Neuropsychology',
    route: '/neuropsychologie/',
    routeEn: '/en/neuropsychology/',
    id: 3,
  },
  {
    label: 'Sportmedizin',
    labelEn: 'Sports Medicine',
    route: '/sportmedizin-laktattest/',
    routeEn: '/en/sports-medicine-lactatetest/',
    id: 4,
  },
  {
    label: 'Schlafmedizin',
    labelEn: 'Sleep Medicine',
    route: '/schlafmedizin-muenchen/',
    routeEn: '/en/sleep-medicine-munich/',
    id: 5,
  },
  {
    label: 'Psychiatrie',
    labelEn: 'Psychiatry',
    route: '/psychiatrie/',
    routeEn: '/en/psychiatry/',
    id: 6,
  },
  {
    label: 'Team',
    labelEn: 'Team',
    route: '/team/',
    routeEn: '/en/team/',
    id: 7,
  },
  {
    label: 'Termin vereinbaren',
    labelEn: 'Arrange Appointment',
    route: '/terminvereinbarung/',
    routeEn: '/en/arrange-appointment/',
    id: 8,
  },
  {
    label: 'Kontakt',
    labelEn: 'Contact',
    route: '/kontakt/',
    routeEn: '/en/contact/',
    id: 9,
  },
];

type Props = { white: boolean };

const Menu: React.FC<Props> = ({ white }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <Container
        css={{
          background: white ? '' : 'var(--primary-color)',
          padding: white ? '' : '2rem',
          paddingTop: white ? '' : '1rem',
          paddingBottom: white ? '' : '1rem',
          margin: white ? '' : '-2rem',
        }}
        white={white}
      >
        {MenuItems.map(item => {
          return (
            <MenuItem key={item.id}>
              <Link
                key={item.id}
                css={{ color: white ? 'white' : 'black' }}
                to={i18n.language === 'de' ? item.route : item.routeEn}
              >
                {i18n.language === 'de' ? item.label : item.labelEn}
              </Link>
            </MenuItem>
          );
        })}
      </Container>
    </>
  );
};

const Container = styled.div`
  a {
    font-size: 1rem;
    text-decoration: none;
    font-weight: normal;
  }

  a:hover {
    color: ${props => (props.white ? 'var(--primary-color)' : 'white')};
  }

  z-index: 100;
`;

const MenuItem = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export default Menu;
